import React, { FunctionComponent } from 'react';
import { Typography } from '@mui/material';

import './index.scss';

interface Props {
  sections: Section[] | null;
}

export interface Section {
  title: string;
  infos: {
    title: string;
    description: string | number;
  }[];
}

const CalibrationEstimatePage: FunctionComponent<Props> = ({
  sections,
}) => {
  return (
    <div className="calibration-estimate-page-comp">
      <Typography variant="h4" style={{ marginBottom: '40px' }}>
        견적 요청
      </Typography>
      {sections && (
        sections.map((section, sectionIndex) => (
          <div className="section" key={sectionIndex}>
            <Typography variant="h6" style={{ marginBottom: '12px' }}>
              {section.title}
            </Typography>
            <div className="info-container">
              {section.infos.map((info, infoIndex) => (
                <div className="info" key={infoIndex}>
                  <div className="title">
                    <Typography>
                      {info.title}
                    </Typography>
                  </div>
                  <div className="description">
                    <Typography
                      style={{ whiteSpace: 'pre' }}
                    >
                      {info.description}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CalibrationEstimatePage;
